import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

import { Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

import GithubIcon from '@static/icons/github.svg';
import InstagramIcon from '@static/icons/instagram.svg';
import TwitterIcon from '@static/icons/twitter.svg';

const SOCIAL = [
  {
    icon: GithubIcon,
    link: 'github_url',
  },
  {
    icon: InstagramIcon,
    link: 'instagram_url',
  },
  {
    icon: TwitterIcon,
    link: 'twitter_url',
  },
];

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        wordpressAcfOptions {
          options {
            footer_content
            github_url
            twitter_url
            instagram_url
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <FooterWrapper>
          <StyledContainer>
            <Copyright dangerouslySetInnerHTML={{ __html: data.wordpressAcfOptions.options.footer_content }} >
            </Copyright>
            <SocialIcons>
              {SOCIAL.map(({ icon, link }) => {
                return data.wordpressAcfOptions.options[link].trim() !== '' &&
                    <ExternalLink href={data.wordpressAcfOptions.options[link]} key={icon}>
                      <img src={icon} alt="link"/>
                  </ExternalLink>
              })}
            </SocialIcons>
          </StyledContainer>
        </FooterWrapper>
      </React.Fragment>
    )}
  />
);

const StyledContainer = styled(Container)`
  display: flex !important;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
    text-align: center;
  }
`;

const SocialIcons = styled.div`
  flex:0 1 auto;

  img {
    margin: 0 8px;
    width: 24px;
    height: 24px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-top: 40px;
  }
`;

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.color.white.regular};
  padding: 32px 0 48px;
`;

const Copyright = styled.div`
  font-family: ${props => props.theme.font.secondary};
  flex:0 1 auto;
  ${props => props.theme.font_size.small};
  
  color: ${props => props.theme.color.black.lighter};
  p {
    color: ${props => props.theme.color.black.lighter};
  ${props => props.theme.font_size.small};
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`;




export default Footer;
