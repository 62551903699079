import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';

import { ReactComponent as WaxLogo } from '@images/logos/wax-logo.svg';

import { Container } from '@components/global';
import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
} from './style';

import { ReactComponent as MenuIcon } from '@static/icons/menu.svg';

const NAV_ITEMS = [
    {
      title: 'Who we are',
      slug: 'who-we-are'
    },
    {
      title: 'How we work',
      slug: 'how-we-work'
    },
    {
      title: 'Happy customers',
      slug: 'happy-customers'
    }, {
      title: 'Get in touch',
      slug: 'get-in-touch'
    }
  ];

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.navRef = React.createRef();
        this.state = {
            mobileMenuOpen: false,
            scrollPos: 0
        };
        this.handleScroll = this.handleScroll.bind(this);

    }
    componentDidMount(){
        window.addEventListener('scroll',  this.handleScroll);
        this.handleScroll();

    }
    componentWillUnmount(){
        window.removeEventListener('scroll',  this.handleScroll);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.scrollPos !== this.state.scrollPos && this.navRef.current){
            this.navRef.current.style.transform= 'translateY(' + (parseInt((19/140)*this.state.scrollPos)) + 'px)'
        }
    }

    handleScroll = () => {
        let winScroll = 140;
        if(window.innerWidth > 990) {
            winScroll = Math.min(Math.max(parseInt(document.body.scrollTop || document.documentElement.scrollTop ), 0), 140);
        }
        this.setState({
            scrollPos: winScroll,
        })
    }
    toggleMobileMenu = () => {
        this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }));
    };
    closeMobileMenu = () => {
        if (this.state.mobileMenuOpen) {
            this.setState({ mobileMenuOpen: false });
        }
    };

  getNavAnchorLink = item => (
    <AnchorLink offset='50' href={`#${item.slug}`} onClick={this.closeMobileMenu}>
      {item.title}
    </AnchorLink>
  );

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile} ref={this.navRef}>
      <Scrollspy
        items={NAV_ITEMS.map(item => item.slug)}
        currentClassName="active"
        mobile={mobile}
        offset={-52}
      >
        {NAV_ITEMS.map(navItem => (
          <NavItem key={navItem.slug}>{this.getNavAnchorLink(navItem)}</NavItem>
        ))}
      </Scrollspy>
    </NavListWrapper>
  );

  render() {
    const { mobileMenuOpen, scrollPos } = this.state;
      const navShift = {
          transform: 'translateY(-' + scrollPos + 'px)',
          padding:'0px'
      };
      const logoShift = {
          transform: 'translateY(' + (scrollPos-(parseInt(16/140*scrollPos))) + 'px) scale(' + (1-(0.4/140*scrollPos)) + ')',

      };
    return (
      <Nav {...this.props} style={navShift}>
        <StyledContainer>
          <Brand style={logoShift}><a style={{ display: 'block' }} href="/" aria-label="Wax RDC"><WaxLogo /></a></Brand>
          <Mobile>
            <button onClick={this.toggleMobileMenu} style={{ color: 'black', marginRight:'16px' }}>
              <MenuIcon />
            </button>
          </Mobile>

          <Mobile hide>{this.getNavList({})}</Mobile>
        </StyledContainer>
        <Mobile>
          {mobileMenuOpen && (
            <MobileMenu>
              <Container>{this.getNavList({ mobile: true })}</Container>
            </MobileMenu>
          )}
        </Mobile>
      </Nav>
    );
  }
}

export default Navbar;
